import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/image-about_us.jpg'
import starIcon from '../../assets/images/star-icon.png'
//import icon4 from '../../assets/images/icons/port.png'
//import icon5 from '../../assets/images/icons/wm.png'
//import icon6 from '../../assets/images/icons/icon6.png'
//import icon7 from '../../assets/images/icons/icon7.png'
import icon4 from '../../assets/images/about/com.png'
import icon5 from '../../assets/images/about/world.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import { Helmet } from 'react-helmet'

const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <Helmet>
                <title>Bumi Nusantara Group</title>
                <meta charSet='utf-8' />
                <meta name='title' content='Bumi Nusantara Group' />
                <meta name="description" content="Bumi Nusantara Group" />
                <meta name='keywords' content='Bumi Nusantara Group, Bumi Nusantara Jaya, Bumi Nusantara Bara, Maharaja Mineral, Energi, Batu Bara, Biji Besi, Nikel, Iron Ore, BNJ, BNG, BNB, MRM, Trading, Binaia Energi Nusantara, Maha Raja Energi, Adimitra Hidro Nusantara, Arta Samudra Petroleum' />
                <meta name='author' content='BNG' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:url' content='https://buminusa.co.id' />
                <meta property='og:url' content='https://buminusagroup.co.id' />
                <meta property='og:site_name' content='Bumi Nusantara Group' />
                <meta property='article:publisher' content='Get info PT. Maharaja Nusantara Energi' />
                <meta property='og:title' content='Bumi Nusantara Group' />
                <meta property='og:description' content='Welcome Bumi Nusantara Group' />
                <meta name="og:title" property="og:title" content="Bumi Nusantara Group"></meta>
                <meta name="twitter:card" content="Bumi Nusantara Group"></meta>
                <link rel="canonical" href="https://buminusa.co.id"></link>
                <link rel='canonical' href='https://buminusagroup.co.id'></link>

               
                <meta property="og:image" content="https://buminusagroup.co.id/upload/bnjgroup.png" />
            </Helmet>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="about-content">
                            <div className="blog-slider">

                                <div className='blog-slider__item swiper-slide'>

                                    <div className='blogimg'>
                                        <img src={aboutImage} alt='' />

                                    </div>
                                    <div>&nbsp;</div>
                                    <div style={{ justifyContent: 'center', color: 'white' }}>
                                        <h2 >Bumi Nusantara Group</h2>
                                        <p style={{color: 'white'}}>At Bumi Nusantara Group we pride ourselves on our strategic approach to management, as well as our commitment to the highest ethical standards. We work closely with our portfolio companies to provide strategic guidance, operational support, and access to capital to help them achieve their goals.</p>

                                        <p style={{color: 'white'}}>With a diverse portfolio of businesses across multiple sectors, we have a proven track record of creating value for our partners and customers. Our experienced team of professionals and industry experts are committed to delivering exceptional results through a disciplined approach to strategic planning and operational excellence.</p>

                                        <p style={{color: 'white'}}>We are committed to delivering value to our partners and customers by fostering growth and innovation. Our philosophy is rooted in long-term thinking, and we strive to create sustainable value for all our stakeholders.</p>
                                        <div>&nbsp;</div>
                                        
                                        <ul style={{paddingLeft: '0', listStyleType: 'none', display: 'block', marginBottom: '25px', marginTop: '-20px', marginLeft: '-15px', marginRight: '-15px', overflow: 'hidden' }}>

                                            <li style={{width: '30%', marginLeft: '5px', marginRight: '5px', marginTop: '30px', float: 'left', display: 'block', border: '1px', borderRadius: '20px', padding: '5px', position: 'relative', background: '#FFFFFF'}}>
                                                
                                                <img src={icon4} alt="banner" />
                                                {/*<h3 style={{color: '#0F477C'}}>20+</h3>*/}
                                                {/*<p style={{color: '#0F477C'}}>Portfolio Companies</p>*/}
                                            </li>
                                            <li style={{width: '30%', marginLeft: '5px', marginRight: '5px', marginTop: '30px', float: 'left', display: 'block', border: '1px', borderRadius: '20px', padding: '5px', position: 'relative', background: '#FFFFFF'}}>
                                                <img src={icon5} alt="banner" />
                                                
                                                {/*<p style={{color: '#0F477C'}}>Worldwide Market</p>*/}
                                            </li>


                                        </ul>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
                </div>*/}
        </section>
    )
}

export default AboutUsContent;